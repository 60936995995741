
.mdl-button.mdl-button--colored {
    color: var(--primary)
}

.mdl-button--raised.mdl-button--colored {
    background: var(--primary);
    color: #fff
}

.mdl-button--raised.mdl-button--colored:hover {
    background-color: var(--primary)
}

.mdl-button--raised.mdl-button--colored:active {
    background-color: var(--primary)
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
    background-color: var(--primary)
}

.mdl-button--primary.mdl-button--primary {
    color: var(--primary)
}

.mdl-button--primary.mdl-button--primary.mdl-button--fab,
.mdl-button--primary.mdl-button--primary.mdl-button--raised {
    color: #fff;
    background-color: var(--primary)
}


.mdl-progress>.progressbar {
    background-color: var(--primary);
    z-index: 1;
    left: 0
}

.mdl-progress>.bufferbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), linear-gradient(to right, var(--primary), var(--primary));
    z-index: 0;
    left: 0
}

@supports (-webkit-appearance:none) {

    .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)>.auxbar,
    .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)>.auxbar {
        background-image: linear-gradient(to right, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), linear-gradient(to right, var(--primary), var(--primary));
        mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=)
    }
}

.mdl-progress:not(.mdl-progress--indeterminate)>.auxbar,
.mdl-progress:not(.mdl-progress__indeterminate)>.auxbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), linear-gradient(to right, var(--primary), var(--primary))
}

.mdl-progress.mdl-progress--indeterminate>.bar1,
.mdl-progress.mdl-progress__indeterminate>.bar1 {
    background-color: var(--primary);
    animation-name: indeterminate1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.mdl-progress.mdl-progress--indeterminate>.bar3,
.mdl-progress.mdl-progress__indeterminate>.bar3 {
    background-image: none;
    background-color: var(--primary);
    animation-name: indeterminate2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
    border-color: var(--primary)
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
    border-color: var(--primary)
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
    border-color: var(--primary)
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: var(--primary)
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: var(--primary);
    font-size: 12px;
    top: 4px;
    visibility: visible
}

.mdl-textfield__label:after {
    background-color: var(--primary);
    bottom: 20px;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    visibility: hidden;
    width: 10px
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: var(--primary)
}